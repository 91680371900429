<template>
  <div class="ps-3 pe-3">
    <h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-geo-alt"
        viewBox="0 0 16 16"
      >
        <path
          d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
        />
        <path
          d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
      </svg>
      Address
    </h3>
    <div class="indented-address">
      <address>
        Bonar House Clinic<br />
        5 Church Road<br />
        Hadleigh<br />
        Benfleet<br />
        SS7 2DQ<br />
        <!--<what3words-address words="hooks.rents.jumpy" />-->
      </address>
    </div>
    <h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-phone"
        viewBox="0 0 16 16"
      >
        <path
          d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
        />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
      Telephone Number
    </h3>
    <div class="indented">
      <p><a class="bonar-links" href="tel:01702558574">01702 558574</a></p>
    </div>
    <h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-envelope"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
        />
      </svg>
      Email
    </h3>
    <div class="indented">
      <p>
        <a class="bonar-links" href="mailto:reception@bonarhouseclinic.co.uk"
          >reception@bonarhouseclinic.co.uk</a
        >
      </p>
    </div>
    <!--<h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-link"
        viewBox="0 0 16 16"
      >
        <path
          d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"
        />
        <path
          d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"
        />
      </svg>
      Useful links
    </h3>-->
    <!-- <div class="indented">
      <a class="bonar-links" href=""> Covid Update </a><br />

      <a class="bonar-links" href=""> Privacy Policy </a><br />

      <a class="bonar-links" href=""> Cancellation Policy</a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "SalonDetails",
};
</script>

<style>
.indented {
  padding-left: 42px;
}
.indented-table {
  padding-left: 34px;
}
.indented-address {
  padding-left: 38px;
}
</style>