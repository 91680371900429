<template>
  <Header />
  <div>
    <Category :category="category" />
    <div class="container">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-3 mt-5">
          <p class="justify-content-center mt-2">
            {{ servicesMappings[category].value }}
          </p>
        </div>
        <div class="col-7">
          <div class="mt-5" v-for="nav in navigation.navigation" :key="nav.id">
            <div v-for="children in nav.children" :key="children.id">
              <div v-if="children.id == category" class="d-flex">
                <ul class="list-inline mx-auto justify-content-center">
                  <div v-for="child in children.children" :key="child.id">
                    <div v-if="child.path">
                      <li class="pt-2 px-3">
                        <button
                          type="button"
                          class="btn btn-bonar mt-3 w-100 uppercase"
                        >
                          <a
                            class="sub-link"
                            v-if="child.path"
                            :href="child.path"
                            >{{ child.name }}</a
                          >
                        </button>
                      </li>
                    </div>
                    <div v-else>
                      <div v-for="sub in child.children" :key="sub.id">
                        <li class="pt-2 px-3">
                          <button
                            type="button"
                            class="btn btn-bonar mt-3 w-100 uppercase"
                          >
                            <a
                              class="sub-link"
                              v-if="sub.path"
                              :href="sub.path"
                              >{{ sub.name }}</a
                            >
                          </button>
                        </li>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>

  <AcceptCookies />
  <Copyright />
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import Header from "@/components/header/Header";
import AcceptCookies from "@/components/footer/AcceptCookies";
import json from "@/assets/data/services.json";
import serviceMappingsJson from "@/assets/data/service-mappings.json";
import navigation from "@/assets/data/navigation.json";
import Category from "@/components/treatment/Category";
import Copyright from "@/components/content/Copyright";

export default {
  setup() {
    const siteData = reactive({
      title: `Bonar House Clinic - Our Services`,
      description: `Bonar House Clinic -`,
      keyWords: `Beauty salon, Beautician, Tanning salon, Skin treatments, Waxing salon, Massage salon, Massage treatment,Spa, Spa salon,Spa treatments, Make up, Make up artist`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description + " Our Services"),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keyWords),
        },
      ],
    });
  },
  name: "ServicesLanding",
  data: function() {
    return {
      services: json,
      servicesMappings: serviceMappingsJson,
      navigation: navigation,
      servicesId: this.$route.params.serviceId,
      category: this.$route.params.category,
      service: json[this.$route.params.serviceId],
    };
  },
  methods: {
    getTreatment() {},
  },
  components: {
    Header,
    AcceptCookies,
    Category,
    Copyright,
  },
};
</script>

<style>
.uppercase {
  text-transform: uppercase;
}
.sub-link,
.sub-link:hover {
  color: #fff;
}
</style>
