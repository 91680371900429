<template>
  <div
    id="carouselExampleDark"
    class="carousel carousel-dark slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="2"
          aria-label="Slide 3"
      ></button>
      <!--<button
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide-to="3"
        aria-label="Slide 4"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide-to="4"
        aria-label="Slide 5"
      ></button>-->
    </div>
    <div class="carousel-inner">
      <div
        class="carousel-item active"
        data-bs-interval="20000"
        style="background-color: #fff"
      >
        <a href="/services/for-the-body/emsella-pelvic-floor-rehabilitation">
          <picture>
            <source
              srcset="/carousel/Emsella600.2.png"
              media="(max-width: 1000px)"
            />
            <source
              srcset="/carousel/EmsellaDT.png"
              media="(max-width: 600px)"
            />
            <img
              class="d-block mx-auto img-fluid"
              src="/carousel/EmsellaDT.png"
              alt="Baby Sleeping"
            />
          </picture>
          <div class="carousel-caption d-none d-md-block">
            <div class="d-inline-flex my-2 my-lg-0 mx-auto">
              <button type="button" class="btn btn-bonar mt-3 w-100">
                TELL ME MORE
              </button>
            </div>
          </div>
        </a>
      </div>
      <div class="carousel-item" data-bs-interval="20000">
        <a href="/services/for-the-body/dermalux-led-therapy">
          <picture>
            <source
              srcset="/carousel/Dermalux600.png"
              media="(max-width: 1000px)"
            />
            <source
              srcset="/carousel/DermaluxDT.png"
              media="(max-width: 600px)"
            />
            <img
              class="d-block mx-auto img-fluid"
              src="/carousel/DermaluxDT.png"
              alt="Baby Sleeping"
            />
          </picture>
          <div class="carousel-caption d-none d-md-block">
            <div class="d-inline-flex my-2 my-lg-0 mx-auto">
              <button type="button" class="btn btn-bonar mt-3 w-100">
                Tell me more
              </button>
            </div>
          </div>
        </a>
      </div>
      <div class="carousel-item" data-bs-interval="20000">
        <a href="/services/for-the-face/em-face">
          <picture>
            <source
                srcset="/services/emface/Emface_mobile3.png"
                media="(max-width: 1000px)"
            />
            <source
                srcset="/services/emface/Emface_mobile3.png"
                media="(max-width: 600px)"
            />
            <img
                class="d-block mx-auto img-fluid"
                src="/services/emface/Emface_desktop4.png"
                alt="EMFACE"
            />
          </picture>
          <div class="carousel-caption d-none d-md-block">
            <div class="d-inline-flex my-2 my-lg-0 mx-auto">
              <button type="button" class="btn btn-bonar mt-3 w-100">
                Tell me more
              </button>
            </div>
          </div>
        </a>
      </div>
      <!--<div class="carousel-item" data-bs-interval="2000">
        <a href="/services/for-the-body/dermalux-led-therapy">
          <img
            srcset="
              /carousel/Skeyndor600.png 600w,
              /carousel/SkeyndorDT.png  800w
            "
            sizes="(max-width: 600px) 600px,
            (min-width: 601px) 800px"
            src="/carousel/SkeyndorDT.png"
            class="d-block mx-auto img-fluid"
            alt="Skeyndor"
          />
          
          <div class="carousel-caption d-none d-md-block">
            <div class="d-inline-flex my-2 my-lg-0 mx-auto">
              <button type="button" class="btn btn-bonar mt-3 w-100">
                Tell me more
              </button>
            </div>
          </div>
        </a>
      </div>
      <div class="carousel-item">
        <img
          src="@/assets/images/carousel/carousel3.jpg"
          class="d-block w-100"
          alt="Treatment 3"
        />
        <div class="carousel-caption d-none d-md-block">
          <div class="d-inline-flex my-2 my-lg-0 mx-auto">
            <a href="/contact-us">
              <button
                type="button"
                class="btn btn-bonar-header header-buttons mt-3 w-100"
              >
                <strong>BOOK YOUR FREE CONSULTATION</strong>
              </button></a
            >
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="@/assets/images/carousel/carousel4.jpg"
          class="d-block w-100"
          alt="Treatment 4"
        />
        <div class="carousel-caption d-none d-md-block">
          <div class="d-inline-flex my-2 my-lg-0 mx-auto">
            <a href="/contact-us">
              <button
                type="button"
                class="btn btn-bonar-header header-buttons mt-3 w-100"
              >
                <strong>BOOK YOUR FREE CONSULTATION</strong>
              </button></a
            >
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="@/assets/images/carousel/carousel5.jpg"
          class="d-block w-100"
          alt="Treatment 5"
        />
        <div class="carousel-caption d-none d-md-block">
          <div class="d-inline-flex my-2 my-lg-0 mx-auto">
            <a href="/contact-us">
              <button
                type="button"
                class="btn btn-bonar-header header-buttons mt-3 w-100"
              >
                <strong>BOOK YOUR FREE CONSULTATION</strong>
              </button></a
            >
          </div>
        </div>
      </div>-->
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleDark"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleDark"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
};
</script>

<style>
.carousel-content {
  position: absolute;
  bottom: 10%;
  left: 5%;
  z-index: 20;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  width: 63rem;
  font-size: 3rem;
}
.carousel-content p {
  float: left;
}
h2.carousel-header {
  font-size: 3rem;
}
.carousel-caption p {
  font-style: italic;
}
</style>