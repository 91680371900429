<template>
  <Header />
  <div>
    <Category :category="category" />
    <Description :service="service" />
    <div v-if="service.show" class="container">
      <div v-for="prices in service.prices" :key="prices.treatment" class="row">
        <h3 v-if="prices.title" class="mt-5">
          <u>{{ prices.title }}</u>
        </h3>
        <p class="mt-2">{{ prices.description }}</p>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10 mt-4 mb-4 mt-md-0 justify-content-center">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="row"></th>
                  <th
                    v-if="service.showPriceDescription"
                    style="width: 27.33%"
                    class=""
                    scope="col"
                  >
                    Treatment
                  </th>
                  <th v-else style="width: 50%" class="pe-5" scope="col">
                    Treatment
                  </th>
                  <th v-if="service.showPriceDescription" scope="col">
                    Description
                  </th>
                  <th
                    v-if="service.showPriceDescription"
                    style="width: 27.33%"
                    class=""
                    scope="col"
                  >
                    Pricing
                  </th>
                  <th v-else style="width: 50%" class="ps-2" scope="col">
                    Pricing
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="content in prices.content" :key="content.treatment">
                  <td v-if="content.border" class="border-top" scope="row"></td>
                  <td v-else scope="row"></td>
                  <td
                    v-if="content.border"
                    class="ml-1 mobile-wrap border-top"
                    v-html="content.treatment"
                  ></td>
                  <td
                    v-else
                    class="ml-1 mobile-wrap"
                    v-html="content.treatment"
                  ></td>
                  <td
                    v-if="service.showPriceDescription && content.border"
                    class="border-top"
                    v-html="content.description"
                  ></td>
                  <td
                    v-else-if="service.showPriceDescription && !content.border"
                    v-html="content.description"
                  ></td>
                  <td
                    v-if="content.border"
                    class="mr-1 mobile-wrap border-top"
                    v-html="content.pricing"
                  ></td>
                  <td
                    v-else
                    class="mr-1 mobile-wrap"
                    v-html="content.pricing"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
    <div v-if="service.testimonials && service.show" class="container mt-5">
      <h4>Testimonials</h4>
      <p
        v-for="testimonial in service.testimonials"
        :key="testimonial.id"
        class="mt-3"
      >
        "{{ testimonial.content }}"
      </p>
    </div>
    <!--<div class="container mt-5">
      <h4>Conditions</h4>
      <p v-for="conditions in service.links" :key="conditions.name">
        <router-link :to="conditions.url">{{ conditions.name }}</router-link>
      </p>
    </div>-->
  </div>
  <AcceptCookies />
  <Copyright />
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import Header from "@/components/header/Header";
import AcceptCookies from "@/components/footer/AcceptCookies";
import json from "@/assets/data/services.json";
import Category from "@/components/treatment/Category";
import Description from "@/components/treatment/Description";
import Copyright from "@/components/content/Copyright";

export default {
  setup() {
    const route = useRoute();

    const siteData = reactive({
      title: `Bonar House Clinic - `,
      description: `Bonar House Clinic -`,
      keyWords: `Beauty salon, Beautician, Tanning salon, Skin treatments, Waxing salon, Massage salon, Massage treatment,Spa, Spa salon,Spa treatments, Make up, Make up artist`,
    });

    useHead({
      // Can be static or computed
      title: computed(
        () =>
          siteData.title +
          " [" +
          route.params.category.replace(/-/g, " ") +
          " | " +
          route.params.serviceId +
          "]"
      ),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description + " Services"),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keyWords),
        },
      ],
    });
  },
  name: "Services",
  data: function() {
    return {
      services: json,
      servicesId: this.$route.params.serviceId,
      category: this.$route.params.category,
      service: json[this.$route.params.serviceId],
    };
  },
  methods: {
    getTreatment() {},
  },
  components: {
    Header,
    AcceptCookies,
    Category,
    Description,
    Copyright,
  },
};
</script>

<style></style>
