<template>
  <Header />
  <div style="margin-top: 500px">Not Found</div>
  <AcceptCookies />
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style>
</style>