<template>
  <Header />
  <Carousel />
  <WelcomeTo />
  <Reviews />
  <TreatmentSectionCards />
  <Details />
  <ContactUsForm />
  <Policies />
  <AcceptCookies />
  <Copyright />
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import Header from "@/components/header/Header";
import Carousel from "@/components/content/Carousel";
import AcceptCookies from "@/components/footer/AcceptCookies";
import WelcomeTo from "@/components/content/WelcomeTo";
import TreatmentSectionCards from "@/components/content/TreatmentSectionCards";
import ContactUsForm from "@/components/footer/ContactUsForm";
import Reviews from "@/components/content/Reviews";
import Details from "@/components/content/Details";
import Copyright from "@/components/content/Copyright";

export default {
  setup() {
    const siteData = reactive({
      title: `Welcome to Bonar House Clinic - Home`,
      description: `Bonar House Clinic - `,
      keyWords: `Beauty salon, Beautician, Tanning salon, Skin treatments, Waxing salon, Massage salon, Massage treatment,Spa, Spa salon,Spa treatments, Make up, Make up artist`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description + " Home"),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keyWords),
        },
      ],
    });
  },
  name: "Home",
  components: {
    Header,
    Carousel,
    AcceptCookies,
    WelcomeTo,
    TreatmentSectionCards,
    ContactUsForm,
    Reviews,
    Details,
    Copyright,
  },
};
</script>

<style></style>
