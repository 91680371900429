<template>
  <form class="d-inline-flex my-1 my-lg-0 mx-3">
    <div class="input-group w-100">
      <input
        type="text"
        class="form-control"
        placeholder="Search..."
        aria-label="Search..."
        aria-describedby="search-button"
        v-model="searchString"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        @keydown.enter.prevent="searchForServices"
        @click="focusOnModal"
      />
      <span
        @click="searchForServices"
        class="input-group-text"
        id="search-button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
          ></path>
        </svg>
      </span>
    </div>
  </form>

  <div
    class="modal"
    tabindex="-1"
    id="exampleModal"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">SEARCH RESULTS</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 card-margin">
                <div class="card search-form">
                  <div class="card-body p-0">
                    <form id="search-form">
                      <div class="row">
                        <div class="col-12">
                          <div class="row no-gutters">
                            <div class="col-lg-12 col-md-12 col-sm-12 p-0">
                              <input
                                @keyup.delete="searchForServices"
                                @input="searchForServices"
                                v-model="searchString"
                                placeholder="Enter your search"
                                type="text"
                                class="form-control"
                                id="search"
                                name="search"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card card-margin">
                  <div class="card-body">
                    <div class="row search-body">
                      <div class="col-lg-12">
                        <div class="search-result">
                          <div class="result-header">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="records">
                                  Showing: <b>{{ resultsSize }}</b> result(s)
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="result-body">
                            <div class="container">
                              <div
                                class="row search-results-row mt-3 mb-3"
                                v-for="(result, index) in results"
                                :key="result.name"
                              >
                                <div class="col-sm-2">
                                  <a
                                    class="bonar-links"
                                    :href="
                                      generateLink(
                                        result.category,
                                        resultIds,
                                        index
                                      )
                                    "
                                    >{{ result.name }}</a
                                  >
                                  <p class="m-0">
                                    <small>{{ result.category }}</small>
                                  </p>
                                </div>
                                <div class="col-sm-8">
                                  <p class="type m-0">
                                    {{ slicedDescription(result.description) }}
                                  </p>
                                  <p class="mt-2">
                                    <!--<strong>Treats: </strong>
                                    {{ joinTreatments(result.links) }}-->
                                  </p>
                                </div>
                                <div class="col-sm-2">
                                  <div class="w-100">
                                    <a
                                      :href="
                                        generateLink(
                                          result.category,
                                          resultIds,
                                          index
                                        )
                                      "
                                    >
                                      <button class="btn btn-bonar w-100">
                                        Find out more
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/assets/data/services.json";
export default {
  name: "Search",
  data: function () {
    return {
      searchString: "",
      results: new Set(),
      resultIds: new Set(),
    };
  },
  computed: {
    resultsSize: function () {
      return this.results.size;
    },
  },
  methods: {
    generateLink: function (category, a, index) {
      return (
        "/services/" +
        category.toLowerCase().replaceAll(" ", "-") +
        "/" +
        Array.from(a)[index]
      );
    },
    focusOnModal: function () {
      document.getElementById("search").focus();
    },
    joinTreatments: function (a) {
      return a.map((e) => e.name).join(",");
    },
    slicedDescription: function (str) {
      console.log(str);
      return str.slice(0, 200) + " ...";
    },
    searchForServices: function () {
      var toSearch = this.searchString;
      console.log("-> " + this.searchString);
      if (!toSearch) {
        return "";
      }

      let treatments = new Set();
      let treatmentIds = new Set();

      console.log("searching for " + toSearch.trim());
      for (let [key, value] of Object.entries(services)) {
        //console.log(`${key}: ${value}`);
        console.log(value.description);
        var desc = value.description
          .toLowerCase()
          .indexOf(toSearch.trim().toLowerCase());
        if (desc !== -1) {
          treatments.add(value);
          treatmentIds.add(key);
          console.log("found in description " + value.name);
        }
        //else {
        //  value.content.forEach(function (item) {
        //    var n = item.description
        //      .toLowerCase()
        //      .indexOf(toSearch.trim().toLowerCase());
        //    // console.log(n);
        //    if (n !== -1) {
        //      treatments.add(value);
        //      treatmentIds.add(key);
        //      console.log("found in content description " + value.name);
        //    }
        //  });
        //}
      }
      console.log(treatments.size);
      console.log("treatments are " + treatments);
      this.results = treatments;
      this.resultIds = treatmentIds;
      console.log("results are " + this.results);

      return toSearch;
    },
  },
};
</script>

<style>
.space-for-mobile {
  margin-right: 10px;
}
#search-button {
  cursor: pointer;
}
.modal-backdrop {
  z-index: unset !important;
}
.widget-26 {
  color: #3c4142;
  font-weight: 400;
}

.widget-26 .widget-26-job-emp-img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.search-form {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
}

.search-form input {
  height: 100%;
  background: transparent;
  border: 0;
  display: block;
  width: 100%;
  padding: 1rem;
  height: 100%;
  font-size: 1rem;
}

.search-form select {
  background: transparent;
  border: 0;
  padding: 1rem;
  height: 100%;
  font-size: 1rem;
}

.search-form select:focus {
  border: 0;
}

.search-form button {
  height: 100%;
  width: 100%;
  font-size: 1rem;
}

.search-form button svg {
  width: 24px;
  height: 24px;
}

.search-body {
  margin-bottom: 1.5rem;
}

.search-body .search-filters .filter-list {
  margin-bottom: 1.3rem;
}

.search-body .search-filters .filter-list .title {
  color: #3c4142;
  margin-bottom: 1rem;
}

.search-body .search-filters .filter-list .filter-text {
  color: #727686;
}

.search-body .search-result .result-header {
  margin-bottom: 2rem;
}

.search-body .search-result .result-header .records {
  color: #3c4142;
}

.search-body .search-result .result-header .result-actions {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-body .search-result .result-header .result-actions .result-sorting {
  display: flex;
  align-items: center;
}

.search-body
  .search-result
  .result-header
  .result-actions
  .result-sorting
  span {
  flex-shrink: 0;
  font-size: 0.8125rem;
}

.search-body
  .search-result
  .result-header
  .result-actions
  .result-sorting
  select {
  color: #68cbd7;
}

.search-body
  .search-result
  .result-header
  .result-actions
  .result-sorting
  select
  option {
  color: #3c4142;
}
.search-results-row {
  border-bottom: solid 1px #dee2e6;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .search-body .search-filters {
    display: flex;
  }
  .search-body .search-filters .filter-list {
    margin-right: 1rem;
  }
}

.card-margin {
  margin-bottom: 1.875rem;
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

.card-margin {
  margin-bottom: 1.875rem;
}
.card {
  border: 0;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e6e4e9;
  border-radius: 8px;
}
</style>
