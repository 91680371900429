<template>
  <Navigation />
</template>

<script>
import Navigation from "./components/Navigation";

export default {
  name: "Header",
  components: {
    Navigation,
  },
};
</script>

<style></style>
