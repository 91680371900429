<template>
  <Header />
  <div>
    <Category :category="service.category" />
    <Description :service="service" />
    <div class="container">
      <div class="row justify-content-around">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Treatment</th>
              <th scope="col">Description</th>
              <th scope="col">Pricing</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="prices in service.prices" :key="prices.treatment">
              <th scope="row"></th>
              <td>{{ prices.treatment }}</td>
              <td v-html="prices.description"></td>
              <td v-html="prices.pricing"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="container">
      <h4>Conditions</h4>
      <p v-for="conditions in service.links" :key="conditions.name">
        <router-link :to="conditions.url">{{ conditions.name }}</router-link>
      </p>
    </div>
  </div>
  <AcceptCookies />
  <Copyright />
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import Header from "@/components/header/Header";
import AcceptCookies from "@/components/footer/AcceptCookies";
import json from "@/assets/data/services.json";
import Category from "@/components/treatment/Category";
import Description from "@/components/treatment/Description";
import Copyright from "@/components/content/Copyright";

export default {
  setup() {
    const route = useRoute();

    const siteData = reactive({
      title: `Bonar House Clinic - `,
      description: `Bonar House Clinic -`,
      keyWords: `Beauty salon, Beautician, Tanning salon, Skin treatments, Waxing salon, Massage salon, Massage treatment,Spa, Spa salon,Spa treatments, Make up, Make up artist`,
    });

    useHead({
      // Can be static or computed
      title: computed(
        () =>
          siteData.title +
          " [" +
          route.params.category.replace(/-/g, " ") +
          " | " +
          route.params.serviceId +
          "]"
      ),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description + " Services"),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keyWords),
        },
      ],
    });
  },
  name: "Conditions",
  data: function() {
    return {
      services: json,
      servicesId: this.$route.params.serviceId,
      service: json[this.$route.params.serviceId],
    };
  },
  methods: {
    getTreatment() {},
  },
  components: {
    Header,
    AcceptCookies,
    Category,
    Description,
    Copyright,
  },
};
</script>

<style></style>
