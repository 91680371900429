<template>
  <div id="treatment-cards-section" class="container-fluid">
    <div class="row g-0">
      <div class="col-2"></div>
      <div
        v-for="cardItem in cards"
        :key="cardItem.id"
        class="col-md-2 mt-4 mt-md-0 justify-content-center"
      >
        <div
          class="card card-custom h-100 shadow-lg p-3 mb-5 mx-3 bg-body rounded"
        >
          <img
            class="card-img-top"
            :src="cardItem.image"
            alt="Card image cap"
          />
          <div class="card-body">
            <h4 class="card-title text-center">{{ cardItem.title }}</h4>
            <p class="card-text">
              {{ cardItem.text }}
            </p>
          </div>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <a :href="cardItem.link" class="btn btn-bonar">{{
                cardItem.linkText
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
import json from "@/assets/data/cards.json";
export default {
  name: "TreatmentSectionCards",
  data: function () {
    return {
      cards: json.cards,
    };
  },
};
</script>

<style>
#treatment-cards-section {
  padding: 4rem 0;
}
.card-custom {
  margin: auto;
}
.card-title {
  font-weight: bolder;
}
.card-text {
  font-size: 1.2rem;
}
</style>