<template>
  <div id="reviews-section" class="container-fluid mt-5 px-5">
    <div class="row">
      <div
        data-romw-token="iineY1twAohlCR8ukLXYMRK0CJgNnYM8pyXrB17pIgJB4IQbZF"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reviews",
};
</script>

<style>
#reviews-section {
  background-color: #dccece;
  padding: 4rem 0;
}
</style>