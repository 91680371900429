<template>
  <div class="container-fluid">
    <div class="row g-0">
      <div class="col-md-2"></div>

      <div class="col-md-8 welcomeText mt-5">
        <h1>Welcome to Bonar House Clinic</h1>
        <p class="mt-5">
          Whether you are one of our many regular clients or are thinking of
          visiting for the first time, I hope you enjoy reading about the range
          of treatments that we offer.
        </p>
        <p class="mt-3">
          The beauty industry is ever evolving and it can be confusing to know
          which treatments are the best option for your personal concerns. You
          can have every confidence in the range of services and products that
          we offer as they are all tried and tested with proven results. Please
          take your time to browse through the website or download our brochure
          and hopefully you will find a treatment option to suit your needs.
        </p>
        <p class="mt-3">
          Please do not hesitate to get in touch if you have any questions about
          a particular treatment, would like to book a free consultation for
          some advice or simply want to get booked in for an appointment. Our
          friendly reception team will be delighted to help you on
          <a class="main-link" href="tel:01702558574">01702 558574.</a>
        </p>
        <p class="mt-3">
          Thank you for taking the time to visit our website. We hope to see you
          in Bonar House Clinic soon.
        </p>
        <p>
          <a href="/about-us">
            <button type="button" class="btn btn-bonar mt-4">
              ABOUT US
            </button></a
          >
        </p>
      </div>

      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeTo",
};
</script>

<style>
div.welcomeText h1 {
  font-size: 3rem;
}
div.welcomeText h1,
div.welcomeText p {
  text-align: center;
  margin: auto;
}
a.main-link {
  color: #000;
}
</style>