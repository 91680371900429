import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import router from "./router";
import VueCookies from "vue3-cookies";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueGtag from "vue-gtag";
import { createHead } from "@vueuse/head";

import "@/assets/css/global.css";

const head = createHead();

createApp(App)
  .use(router)
  .use(VueCookies)
  .use(bootstrap)
  .use(
    VueGtag,
    {
      appName: "BonarHouseClinic",
      pageTrackerScreenviewEnabled: true,
      config: { id: "G-836JV65TS8" },
    },
    router
  )
  .use(head)
  .component(VueCountdown.name, VueCountdown)
  .mount("#app");
