<template>
  <Header />
  <div>
    <div id="service-content" class="row h-100 w-100 pt-5 pb-5 g-0">
      <div class="col-sm-12 my-auto">
        <div><h2 class="text-center">About Us</h2></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 mt-4 mt-md-0">
        <div class="mb-5 mt-5 clearfix">
          <div class="float-md-start me-4">
            <img
              src="/aboutus/AboutUsSquare.jpg"
              class="shadow-lg p-3 mb-5 bg-body rounded"
              width="300"
            />
          </div>
          <p>
            We are a friendly and professional beauty and aesthetics salon
            hidden in the heart of Hadleigh, Essex.
          </p>
          <p class="mb-3">
            We opened in September 2000 and have built a reputation for
            providing an honest and knowledgeable service, specialising
            particularly in the areas of anti-ageing and laser hair removal.
          </p>
          <p class="mb-3">
            We offer an extensive menu of facial treatments ranging from the
            industry leading CACI Non-Surgical Facial to advanced facial
            techniques such as micro-needling and anti-wrinkle injections. We
            offer a free of charge consultation to discuss which treatment
            options may be best for you personally so please don’t hesitate to
            book in if you would simply like some advice.
          </p>
          <p class="mb-3">
            We pride ourselves on working with industry leading brands across
            all of our services so that we can trust in the results that we
            offer. On every visit to Bonar House Clinic, we want you to feel
            confident that you will be treated by a professional, knowledgeable
            therapist using excellent products that deliver real results.
          </p>
          <p class="mb-3">
            If you have any questions, would like to book an appointment or for
            a free of charge consultation, please contact our reception team on
            <a class="main-link" href="tel:01702558574">01702 558574</a> or
            email
            <a class="main-link" href="mailto:reception@bonarhouseclinic.co.uk"
              >reception@bonarhouseclinic.co.uk</a
            >.
          </p>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
  <AcceptCookies />
  <Copyright />
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import Header from "@/components/header/Header";
import AcceptCookies from "@/components/footer/AcceptCookies";
import json from "@/assets/data/aboutus.json";
import Copyright from "@/components/content/Copyright";

export default {
  setup() {
    const siteData = reactive({
      title: `Bonar House Clinic - About Us`,
      description: `Bonar House Clinic - `,
      keyWords: `Beauty salon, Beautician, Tanning salon, Skin treatments, Waxing salon, Massage salon, Massage treatment,Spa, Spa salon,Spa treatments, Make up, Make up artist`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description + " About Us"),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keyWords),
        },
      ],
    });
  },
  name: "AboutUs",
  data: function() {
    return {
      category: "about-us",
      aboutus: json["aboutus"],
    };
  },
  components: {
    Header,
    AcceptCookies,
    Copyright,
  },
};
</script>

<style></style>
