<template>
  <div class="container-fluid">
    <a href="/">
      <img
        class="img-fluid mx-auto d-block pt-3 mb-4"
        src="/logo/bonar-logo.png"
        alt="Bonar House Clinic Logo"
        width="300"
      />
    </a>
  </div>
  <nav
    class="
      navbar navbar-expand-lg navbar-dark
      mt-3
      bg-dark
      justify-content-between
    "
  >
    <div class="container-fluid pb-2 pt-2 background-brown">
      <button
        class="navbar-toggler ms-auto mb-1"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li
            v-for="navItem in navigation"
            :key="navItem.id"
            class="nav-item dropdown background-brown"
          >
            <!--<a :href="navItem.path">
              <button
                type="button"
                class="btn btn-bonar-header header-buttons mt-3 w-100"
              >
                <strong>{{ navItem.name }}</strong>
              </button></a
            >-->
            <a
              v-if="navItem.children.length == 0"
              class="nav-link active brochure"
              aria-current="page"
              :href="navItem.path"
              ><span class="white">{{ navItem.name }}</span></a
            >
            <a
              v-if="navItem.children.length > 0"
              class="nav-link dropdown-toggle active brochure"
              :href="navItem.path"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="white">{{ navItem.name }}</span>
            </a>
            <ul
              v-if="navItem.children.length > 0"
              class="dropdown-menu background-brown"
              aria-labelledby="navbarDropdown"
            >
              <li v-for="children in navItem.children" :key="children.name">
                <a class="dropdown-item" :href="children.path"
                  ><span class="white">{{ children.name }}</span>
                  <span class="white" v-if="children.children">
                    &raquo;</span
                  ></a
                >
                <ul
                  class="submenu dropdown-menu background-brown"
                  v-if="children.children"
                >
                  <li v-for="sub in children.children" :key="sub.name">
                    <a class="dropdown-item" :href="sub.path"
                      ><span class="white">{{ sub.name }}</span>
                      <span class="white" v-if="sub.children"> &raquo;</span></a
                    >
                    <ul
                      class="submenu dropdown-menu background-brown"
                      v-if="sub.children"
                    >
                      <li v-for="multi in sub.children" :key="multi.name">
                        <a class="dropdown-item" :href="multi.path"
                          ><span class="white">{{ multi.name }}</span></a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link active brochure"
              href="https://bonarhouse-brochure.s3.eu-west-2.amazonaws.com/Bonar-House-Clinic-Brochure-March-2024.pdf"
              ><button type="button" class="btn btn-bonar-header w-100">
                <strong class="white">DOWNLOAD BROCHURE</strong>
              </button></a
            >
          </li>
        </ul>
        <div
          id="right-nav-content"
          class="d-flex align-items-between flex-column"
        >
          <HeaderButtons />
          <Search />
          <!--<div class="d-flex flex-column mx-auto mt-3">
            <a
              href="https://bonarhouse-brochure.s3.eu-west-2.amazonaws.com/Brochure+Dec+2019.pdf"
            >
              <button type="button" class="btn btn-bonar-header w-100">
                <strong>DOWNLOAD BROCHURE</strong>
              </button></a
            >
          </div>-->
        </div>
      </div>
    </div>

    <!-- navbar-collapse.// -->

    <!-- container-fluid.// -->
  </nav>
</template>

<script>
import Search from "@/components/header/components/Search";
import json from "@/assets/data/navigation.json";
import HeaderButtons from "@/components/header/components/HeaderButtons";

export default {
  name: "Navigation",
  data: function() {
    return {
      navigation: json.navigation,
    };
  },
  components: {
    Search,
    HeaderButtons,
  },
};
</script>

<style>
#right-nav-content {
  position: fixed;
  right: 0px;
  top: 10px;
}
@media (max-width: 768px) {
  .navbar {
    margin-top: 2rem;
  }
  #right-nav-content {
    position: relative;
  }
  .navbar-nav {
    max-height: 400px;
    overflow-y: scroll;
    margin-left: 0 !important;
  }
  nav div a img {
    margin-top: 1rem;
  }
  nav div {
    padding-bottom: 1rem;
  }
}
.navbar {
  position: relative !important;
}
.navbar-nav {
  margin-left: 5rem;
}
ul > li {
  margin-left: 1rem;
}
.dropdown-item {
  background-color: #2c1100;
}
.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}
.navbar-nav ul li a:hover {
  background-color: #2c1100 !important;
}
.navbar-nav ul li a.active {
  background-color: #2c1100 !important;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

nav {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>
