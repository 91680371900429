<template>
  <div class="ps-3 pe-3">
    <h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-clock"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
        />
        <path
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"
        />
      </svg>
      Opening Times
    </h3>
    <div class="table-responsive indented-table">
      <table class="table">
        <tr>
          <td>Monday</td>
          <td>9am – 9pm</td>
        </tr>
        <tr>
          <td>Tuesday</td>
          <td>9am – 9pm</td>
        </tr>
        <tr>
          <td>Wednesday</td>
          <td>9am – 6pm</td>
        </tr>
        <tr>
          <td>Thursday</td>
          <td>9am – 9pm</td>
        </tr>
        <tr>
          <td>Friday</td>
          <td>9am – 6pm</td>
        </tr>
        <tr>
          <td>Saturday</td>
          <td>9am – 5pm</td>
        </tr>
        <tr>
          <td>Sunday</td>
          <td>Closed</td>
        </tr>
        <tr>
          <td>Bank Holidays</td>
          <td>Closed</td>
        </tr>
      </table>
    </div>
    <h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="currentColor"
        class="bi bi-info"
        viewBox="0 0 16 16"
      >
        <path
          d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
        />
      </svg>
      Parking
    </h3>
    <div class="indented">
      <p>We have a small complimentary car park</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policies",
};
</script>

<style>
.policies #policy-buttons {
  position: relative;
  top: 10rem;
  width: 80%;
}
</style>
