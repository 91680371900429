<template>
  <div id="details-section" class="container-fluid clear-fix">
    <div class="row">
      <div class="col-md-4"><GoogleMap /></div>

      <div class="col-md-4"><SalonDetails /></div>

      <div class="col-md-4"><OpeningTimes /></div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/components/content/GoogleMap";
import SalonDetails from "@/components/content/SalonDetails";
import OpeningTimes from "@/components/content/OpeningTimes";
export default {
  name: "Details",
  components: {
    GoogleMap,
    SalonDetails,
    OpeningTimes,
  },
};
</script>

<style>
#details-section {
  background-color: #dccece;
  padding: 4rem 0;
}
</style>