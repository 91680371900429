<template>
  <div id="contact-us-section" class="container-fluid">
    <div class="row g-0">
      <div class="col-1 col-md-4"></div>
      <div id="contactus" class="col-10 col-md-4">
        <h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-mailbox"
            viewBox="0 0 16 16"
          >
            <path
              d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"
            />
            <path
              d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"
            />
          </svg>
          Contact Us
        </h2>
        <div class="contact-us-content">
          <p>We love to hear from you so drop us a message</p>
          <form
            @submit.prevent="submitForm"
            v-if="!formSubmitted"
            class="row g-3"
          >
            <div class="col-md-6">
              <label for="inputName" class="form-label">Name</label>
              <input
                v-model="name"
                type="text"
                class="form-control"
                id="inputName"
                placeholder="Name"
              />
            </div>
            <div class="col-md-6">
              <label for="inputPhoneNumber" class="form-label"
                >Phone Number</label
              >
              <div class="input-group">
                <div class="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-phone"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                    />
                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                </div>
                <input
                  v-model="phone"
                  type="tel"
                  class="form-control"
                  id="inputPhoneNumber"
                  placeholder="Phone number"
                />
              </div>
            </div>
            <div class="col-12">
              <label for="inputEmail" class="form-label">Email</label>
              <div class="input-group">
                <div class="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-at"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"
                    />
                  </svg>
                </div>
                <input
                  type="email"
                  class="form-control"
                  id="inputEmail"
                  placeholder="Email address"
                />
              </div>
            </div>
            <div class="col-12">
              <label for="inputMessage" class="form-label">Message</label>
              <textarea
                v-model="message"
                class="form-control"
                aria-label="Message"
                rows="5"
                placeholder="Message"
              ></textarea>
            </div>
            <div class="col-12 d-grid gap-2">
              <button class="submit btn btn-bonar" type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-1 col-md-4"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUsForm",
  data() {
    return {
      name: "",
      phone: "",
      message: "",
    };
  },
  methods: {
    submitForm: function() {
      this.formSubmitted = true;
      console.log(this.name);
      location.href =
        "mailto:reception@bonarhouseclinic.co.uk?subject=" +
        "WEBSITE: Name: " +
        this.name +
        " Phone: " +
        this.phone +
        "&body=" +
        this.message;
    },
  },
};
</script>

<style>
#contact-us-section {
  padding: 4rem 0;
  padding-bottom: 120px;
}
.contact-us-content {
  margin-left: 40px;
}
</style>
