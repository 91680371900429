<template>
  <Header />
  <div>
    <div id="service-content" class="row h-100 w-100 pt-5 pb-5 g-0">
      <div class="col-sm-12 my-auto">
        <div><h2 class="text-center">COVID Secure</h2></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 mt-4 mt-md-0">
        <div class="mb-5 mt-5 clearfix">
          <p>
            Bonar House Clinic is a COVID-secure premises and we have implemented some policies and procedures to ensure that the safety of our clients and staff remains a priority. 
          </p>
          <p class="mb-3">
            Please be aware of the following prior to an appointment with us: 
          </p>
          <p class="mb-3">
            <ul>
              <li><strong>Face covering</strong> – we kindly ask that if you are able to do so you wear a face covering throughout the salon and if appropriate for the duration of your treatment.</li>
              <li><strong>Screening</strong> – to try and protect our staff we will ask all clients some screening questions prior to your appointment to ensure you have not recently experienced symptoms of Covid-19. On arrival we will also politely ask you to sanitise your hands and we will check your temperature. Thank you in advance for your co-operation with these checks. </li>
              <li><strong>If you feel unwell</strong>  - please contact us to rearrange your appointment if you feel unwell. During this uncertain time, we must do all we can to prevent our therapists catching and/or spreading any kinds of coughs and colds. If you are feeling poorly, please do call us to reschedule your appointment. </li>
              <li><strong>Sanitising</strong> – we have increased the frequency of sanitisation throughout all areas of the salon. Treatment rooms and treatment equipment are thoroughly sanitised after every client and communal areas of the salon are sanitised every 3-4 hours including high frequency contact points such as door handles. </li>
              <li><strong>Track and Trace</strong> – it is our obligation to co-operate with Track and Trace should we be requested to due to a positive case of Covid-19 within the salon. By attending an appointment with us please be aware you are consenting to us sharing your information with Track and Trace. We ask that all clients check in using our venue poster upon arrival to the salon. </li>
            </ul>          
          </p>
          <p class="mb-3">
            The procedures outlined above are a summary of the key points we feel it is important you know prior to an appointment. Please do not hesitate to contact if you have any questions or concerns regarding our COVID-secure procedures and policies or would like to see our full risk assessment. 
          </p>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
  <AcceptCookies />
  <Copyright />
</template>

<script>
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import Header from "@/components/header/Header";
import AcceptCookies from "@/components/footer/AcceptCookies";
import json from "@/assets/data/aboutus.json";
import Copyright from "@/components/content/Copyright";

export default {
  setup() {
    const siteData = reactive({
      title: `Bonar House Clinic - COVID`,
      description: `Bonar House Clinic -`,
      keyWords: `Beauty salon, Beautician, Tanning salon, Skin treatments, Waxing salon, Massage salon, Massage treatment,Spa, Spa salon,Spa treatments, Make up, Make up artist`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title ),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description + " COVID"),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keyWords),
        },
      ],
    });
  },
  name: "Covid",
  data: function () {
    return {
      category: "about-us",
      aboutus: json["aboutus"],
    };
  },
  components: {
    Header,
    AcceptCookies,
    Copyright,
  },
};
</script>

<style>
</style>