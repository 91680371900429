<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 mt-4 mb-4 mt-md-0 justify-content-center">
        <h3>{{ service.name }}</h3>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
  <div v-if="service.show" class="container">
    <div v-if="service.banner">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 mt-4 mt-md-0">
          <img
            :src="service.banner"
            class="shadow-lg p-3 mb-5 bg-body rounded w-100"
            alt="EMFACE"/>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 mt-4 mt-md-0">
        <div
          v-for="(content, index) in service.content"
          :key="content.id"
          class="mb-1 clearfix"
        >
          <div v-if="content.show === false"></div>
          <div
            v-else
            :class="
              index % 2 == 0 ? 'float-md-start me-4' : 'float-md-end ms-4'
            "
          >
            <img
              :src="content.image"
              width="300"
              class="shadow-lg p-3 mb-5 bg-body rounded"
            />
          </div>
          <p v-html="content.description"></p>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
  <div v-else class="container mt-5 mb-5 pb-5">
    <p class="text-center" style="font-size: 3em">Content coming soon!</p>
  </div>
</template>

<script>
export default {
  name: "Description",
  props: ["service"],
};
</script>

<style>
</style>