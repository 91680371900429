<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  mounted() {
    let what3wordsScript = document.createElement("script");
    what3wordsScript.setAttribute(
      "src",
      "https://assets.what3words.com/sdk/v3/what3words.js"
    );
    document.head.appendChild(what3wordsScript);

    let reviewsScript = document.createElement("script");
    reviewsScript.setAttribute(
      "src",
      "https://reviewsonmywebsite.com/js/v2/embed.js?id=c338ae55b38e487f19ae"
    );
    document.head.appendChild(reviewsScript);

    let smtpScript = document.createElement("script");
    smtpScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
    document.head.appendChild(smtpScript);

    let captchaScript = document.createElement("script");
    captchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js"
    );
    document.head.appendChild(captchaScript);
  },
};
</script>

<style>
@font-face {
  font-family: "Helvectica1";
  font-style: normal;
  font-weight: normal;
  src: local("Helvectica1"),
    url("./assets/fonts/Helvetica LT 45 Light.ttf") format("truetype");
}
@font-face {
  font-family: "Brandon";
  font-style: normal;
  font-weight: normal;
  src: local("Brandon"),
    url("./assets/fonts/HVD Fonts - BrandonText-Regular.otf") format("opentype");
}
@font-face {
  font-family: "New Caledonia LT Std Regular";
  font-style: normal;
  font-weight: normal;
  src: local("New Caledonia LT Std Regular"),
    url("./assets/fonts/NewCaledoniaLTStdRg.woff") format("woff");
}
hr {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
button.btn-bonar {
  color: #fff;
  border-color: #2c1100;
  background-color: #2c1100;
}
button.btn-bonar:hover {
  color: #fff;
  border-color: #2c1100;
  background-color: #2c1100;
}
button.btn-bonar-header {
  color: #2c1100;
  border-color: #89772f;
  background-color: #89772f;
}
a.btn-bonar {
  color: #fff;
  border-color: #2c1100;
  background-color: #2c1100;
}
a.btn-bonar:hover {
  color: #fff;
  border-color: #2c1100;
  background-color: #2c1100;
}
.card-footer {
  background-color: transparent !important;
}
a.bonar-links {
  color: #2c1100;
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
</style>
