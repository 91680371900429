<template>
  <div id="service-content" class="row h-100 w-100 pt-5 pb-5 g-0">
    <div class="col-sm-12 my-auto">
      <div>
        <h2 class="text-center">{{ edit(category) }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Category",
  props: ["category"],
  methods: {
    edit: function (s) {
      var splitStr = s.replaceAll("-", " ").toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
  },
};
</script>

<style>
#service-content {
  background-color: #dccece;
}
</style>