<template>
  <div ref="cookiearea" class="fixed-bottom">
    <div class="alert text-center cookiealert" role="alert">
      <b>Do you like cookies?</b> &#x1F36A; We use cookies to ensure you get the
      best experience on our website.
      <a href="https://cookiesandyou.com/" target="_blank">Learn more</a>
      <button
        @click="acceptCookies"
        type="button"
        class="btn btn-primary btn-sm acceptcookies ml-5"
      >
        I agree
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptCookies",
  methods: {
    acceptCookies() {
      this.$refs.cookiearea.style.display = "none";
      console.log(this.$cookies.keys());
      this.$cookies.set("acceptedCookies", "true", Infinity);
    },
  },
  mounted() {
    let accepted = this.$cookies.isKey("acceptedCookies");
    console.log("cookie is " + accepted);
    if (accepted) {
      this.$refs.cookiearea.style.display = "none";
    }
  },
};
</script>

<style>
.fixed-bottom {
  border-top: 2px solid #000;
  background-color: #fff;
  font-size: 16px;
  height: 100px;
}

.acceptcookies {
  margin-left: 0.5rem;
}
</style>
