<template>
  <div style="padding: 0 2rem">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3130.0499907079093!2d0.6093067159730527!3d51.55388431512415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8daffabd21229%3A0x5aa8088469a4b0b!2sBonar%20House%20Clinic!5e1!3m2!1sen!2suk!4v1623857827808!5m2!1sen!2suk"
      width="100%"
      height="450px"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
};
</script>

<style>
</style>