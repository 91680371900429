import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/pages/Home.vue';
import AboutUs from '@/pages/AboutUs.vue';
import Services from '@/pages/Services.vue';
import ServicesLanding from '@/pages/ServicesLanding.vue';
import Conditions from '@/pages/Conditions.vue';
import NotFound from '@/pages/NotFound.vue';
import Covid from '@/pages/Covid.vue';
//import ComingSoon from '@/pages/ComingSoon'

const routes = [
	//{
	//	path: '/',
	//	name: 'ComingSoon',
	//	component: ComingSoon,
	//}
	//,
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/about-us',
		name: 'AboutUs',
		component: AboutUs,
	},
	{
		path: '/services/:category/:serviceId',
		name: 'Services',
		component: Services,
	},
	{
		path: '/services/:category',
		name: 'ServicesLanding',
		component: ServicesLanding,
	},
	{
		path: '/conditions/:conditionId',
		name: 'Conditions',
		component: Conditions,
	},
	{
		path: '/covid',
		name: 'Covid',
		component: Covid,
	},
	{
		path: "/:catchAll(.*)",
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;